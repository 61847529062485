<template>
    <LiefengContent>
        <template v-slot:title>{{ `设备升级` }}</template>
        <template v-slot:toolsbarRight>
            <Form :label-colon="true" :inline="true" class="search">
                <FormItem>
                    <LiefengCascaderCity @changeCasader="changeCasaders" :resetNum="resetNum" :orgCode="4401" :width="200" :isClear="true" />
                </FormItem>
                <FormItem>
                    <Input :maxlength="20" enter-button v-model.trim="searchData.appCode" placeholder="请输入应用编码" style="margin-right: 10px; width: 150px" />
                </FormItem>
                <FormItem>
                    <Select transfer v-model="searchData.activeStatus" style="width: 200px; text-align: left; margin-right: 10px" placeholder="请选择当前页在线离线状态">
                        <Option value="">{{ "全部" }}</Option>
                        <Option :value="1">{{ "离线" }}</Option>
                        <Option :value="2">{{ "在线" }}</Option>
                    </Select>
                </FormItem>
                <Button type="primary" icon="ios-search" @click="searchBtn" style="margin-right: 10px">查询</Button>
                <Button type="primary" icon="ios-search" @click="resetBtn" style="margin-right: 10px">重置</Button>
                <Button type="info" @click="allUpdate">批量升级</Button>
            </Form>
        </template>

        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                :loading="loading"
                :fixTable="true"
                :curPage="page"
                :total="total"
                :page-size="pageSize"
                :pagesizeOpts="[20, 30, 50, 100]"
                @hadlePageSize="hadlePageSize"
                @tableSelect="tableSelect"
            ></LiefengTable>

            <LiefengModal title="修改社区" :value="switchStatus" @input="changeSwitchStatus" width="300px" height="100px">
                <template v-slot:contentarea>
                    <LiefengCascaderCity @changeCasader="changeCasader" :orgCode="44" :width="200" v-if="showCascader" />
                </template>
                <template v-slot:toolsbar>
                    <Button type="info" @click="changeSwitchStatus(false)" style="margin-right: 10px">取消</Button>
                    <Button type="primary" :loading="btnLoading" @click="enterSave">确定</Button>
                </template>
            </LiefengModal>

            <LiefengModal title="绑定机构" :value="orgStatus" @input="changeorgStatus" width="400px" height="100px">
                <template v-slot:contentarea>
                    <Select clearable filterable v-model="orgCodeSelf" style="width: 200px" @on-change="changeSelectList" :remote-method="remoteMethod">
                        <Option v-for="(item, index) in selectList" :value="item.orgCode" :key="index">{{ item.orgName }}</Option>
                    </Select>
                </template>
                <template v-slot:toolsbar>
                    <Button type="info" @click="changeorgStatus(false)" style="margin-right: 10px">取消</Button>
                    <Button type="primary" @click="orgSave">确定</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengCascaderCity from "@/components/LiefengCascaderCity"
import LiefengModal from "@/components/LiefengModal"

export default {
    components: {
        LiefengContent,
        LiefengTable,
        LiefengCascaderCity,
        LiefengModal,
    },
    data() {
        return {
            searchData: {
                appCode: "",
                activeStatus: "",
                orgCode: "",
            },
            detailMessage: {},
            talbeColumns: [
                {
                    type: "selection",
                    width: 60,
                    align: "center",
                },
                {
                    title: "设备UUID",
                    align: "center",
                    minWidth: 100,
                    key: "terminalUuid",
                },
                {
                    title: "设备名称",
                    align: "center",
                    minWidth: 100,
                    key: "terminalName",
                },
                {
                    title: "绑定社区",
                    align: "center",
                    minWidth: 100,
                    key: "terminalGroupName",
                },
                {
                    title: "绑定机构",
                    align: "center",
                    minWidth: 100,
                    key: "orgName",
                },
                {
                    title: "绑定状态",
                    align: "center",
                    minWidth: 100,
                    key: "orgName",
                    render: (h, params) => {
                        return h("div", {}, params.row.bindOrgCodeSelf == '1' ? '已授权' : params.row.bindOrgCodeSelf == '2' ? '未授权' : '未知状态')
                    },
                },
                {
                    title: "激活时间",
                    align: "center",
                    minWidth: 100,
                    key: "activeTime",
                    render: (h, params) => {
                        return h("div", {}, params.row.activeTime ? this.$core.formatDate(new Date(params.row.activeTime), "yyyy-MM-dd hh:mm:ss") : "")
                    },
                },
                {
                    title: "应用名称",
                    align: "center",
                    minWidth: 100,
                    key: "terminalName",
                },
                {
                    title: "应用编码",
                    align: "center",
                    minWidth: 100,
                    key: "appCode",
                },
                {
                    title: "当前版本号",
                    align: "center",
                    minWidth: 100,
                    key: "version",
                },
                {
                    title: "最新版本号",
                    align: "center",
                    minWidth: 100,
                    key: "newVersion",
                },
                {
                    title: "网络状态",
                    align: "center",
                    minWidth: 100,
                    key: "onlineStatus",
                    render: (h, params) => {
                        return h(
                            "p",
                            {
                                style: {
                                    color: params.row.onlineStatus === "1" ? "red" : "",
                                },
                            },
                            params.row.onlineStatus ? (params.row.onlineStatus === "1" ? "离线" : "在线") : ""
                        )
                    },
                },
                {
                    title: "最新更新时间",
                    align: "center",
                    minWidth: 100,
                    render: (h, params) => {
                        return h("div", {}, params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss") : "")
                    },
                },
                {
                    title: "操作",
                    width: 350,
                    align: "center",
                    fixed:"right",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: {
                                        marginRight: "10px",
                                    },
                                    on: {
                                        click: () => {
                                            this.$core.openLayerFrame({
                                                type: 2,
                                                title: "管理员列表",
                                                content: `/page#/deviceupgradetable?terminalUuid=${params.row.terminalUuid}&orgCodeSelf=${params.row.orgCodeSelf}`,
                                                area: ["100%", "100%"],
                                            })
                                        },
                                    },
                                },
                                "管理员列表"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: {
                                        marginRight: "10px",
                                    },
                                    on: {
                                        click: () => {
                                            this.sendUpgradeCmd(params.row.terminalUuid)
                                        },
                                    },
                                },
                                "升级"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: {
                                        marginRight: "10px",
                                    },
                                    on: {
                                        click: () => {
                                            this.switchStatus = true
                                            this.detailMessage = params.row
                                        },
                                    },
                                },
                                "修改社区"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    on: {
                                        click: async () => {
                                            this.orgName = params.row.orgName
                                            this.orgStatus = true
                                            this.detailMessage = params.row
                                            await this.selectByDimensionId()
                                            this.changeSelectList(params.row.orgCodeSelf)
                                        },
                                    },
                                },
                                "绑定机构"
                            ),
                        ])
                    },
                },
            ],
            tableData: [],
            page: 1,
            pageSize: 20,
            loading: false,
            total: 0,
            switchStatus: false, // 修改社区

            btnLoading: false, //修改loading

            showCascader: false,

            orgCode: "",

            resetNum: 1,

            selectList: [],
            allTableData: [], // 存放当前也所有的数据

            orgCodeSelf: "",
            selectList: [],
            orgStatus: false,
            orgName: "",
            timer: null,
        }
    },
    created() {
        this.getList()
    },
    methods: {
        remoteMethod(orgName) {
            this.$core.debounce(
                () => {
                    this.orgName = orgName
                    this.selectByDimensionId(orgName)
                },
                "timer",
                1000
            )
        },
        changeSelectList(val) {
            this.orgCodeSelf = val
            this.selectList.map(item => {
                if (item.orgCode == this.orgCodeSelf) {
                    this.orgName = item.name
                }
            })
        },
        async selectByDimensionId() {
            if (!this.orgName || this.orgName == "") return
            // this.$get("/orgzz/pc/organization/selectByDimensionId", {
            await this.$get("/orgzz/pc/organization/selectOrganizationAuthList", {
                orgName: this.orgName,
                // dimensionId: "0",
                // appCode: "suiyue",
                // oemCode: "zjsm",
            }).then(res => {
                this.selectList = res.dataList
            })
        },
        orgSave() {
            if (!this.orgCodeSelf || this.orgCodeSelf == "") {
                this.$Message.warning({
                    content: "请选择机构",
                    background: true,
                })
                return
            }
            delete this.detailMessage._index, delete this.detailMessage._rowKey

            this.$post(
                "/gateway/api/sysmartscreen/pc/terminal/saveTerminal",
                {
                    ...this.detailMessage,
                    orgCodeSelf: this.orgCodeSelf,
                },
                { "content-type": "application/json" }
            ).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "绑定成功",
                        background: true,
                    })
                    this.changeorgStatus(false)
                    this.getList()
                } else {
                    this.$Message.error({
                        content: "绑定失败",
                        background: true,
                    })
                    return
                }
            })
        },
        // 批量升级按钮
        allUpdate() {
            if (!this.selectUuidList || this.selectUuidList.length == 0) {
                this.$Message.warning({
                    content: "请勾选数据",
                    background: true,
                })
                return
            } else {
                let idList = []
                this.selectUuidList.map(item => {
                    idList.push(item.terminalUuid)
                })
                this.sendUpgradeCmd(idList.join(","))
            }
        },
        // 表格勾选的列
        tableSelect(val) {
            this.selectUuidList = val
        },
        // 选择社区选项
        changeCasaders(val) {
            this.selectList = val
            if (val && val.length == 3) {
                this.searchData.orgCode = val[val.length - 1]
                this.getList()
            }
            if (val.length == 0) {
                this.searchData.orgCode = ""
            }
        },
        // 确定按钮
        enterSave() {
            this.updateRow(this.orgCode)
        },
        changeCasader(arr) {
            this.orgCode = arr[arr.length - 1]
        },
        changeorgStatus(val) {
            this.orgStatus = val
            if (!val) {
                this.selectList = []
                this.orgName = ""
                this.orgCodeSelf = ""
            }
        },
        //弹窗关闭
        changeSwitchStatus(val) {
            this.switchStatus = val
            if (val) {
                this.showCascader = false
                this.$nextTick(() => {
                    this.showCascader = true
                })
            }
        },
        resetBtn() {
            this.page = 1
            this.searchData.appCode = ""
            this.searchData.activeStatus = ""
            this.getList()
        },
        async searchBtn() {
            // if (this.selectList.length == 0 || this.selectList.length == 3) {
            this.page = 1
            await this.getList()
            if (this.allTableData && this.allTableData.length) {
                let list = []
                if (this.searchData.activeStatus == "1") {
                    // 离线
                    this.allTableData.map(item => {
                        if (item.onlineStatus == "1") {
                            list.push(item)
                        }
                    })
                    this.tableData = list
                    this.total = this.tableData.length
                } else if (this.searchData.activeStatus == "2") {
                    // 在线
                    this.allTableData.map(item => {
                        if (item.onlineStatus == "2") {
                            list.push(item)
                        }
                    })
                    this.tableData = list
                    this.total = this.tableData.length
                }
            }
            // } else {
            //     this.$Message.warning({
            //         content: "请选择到社区后进行查询",
            //         background: true,
            //     })
            // }
        },
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.searchData.activeStatus = ""
            this.getList()
        },
        sendUpgradeCmd(id) {
            this.$Message.loading({
                content: "正在升级，请稍等",
                background: true,
            })
            this.$post("/gateway/api/sysmartscreen/pc/terminal/sendUpgradeCmd", {
                uuidList: id,
            }).then(res => {
                this.$Message.destroy()
                if (res.code == 200) {
                    this.$Message.success({
                        background: true,
                        content: "升级成功",
                    })
                    this.getList()
                } else {
                    this.$Message.error({
                        content: "修改失败",
                        background: true,
                    })
                }
            })
        },
        // 更新数据
        updateRow(orgCode) {
            this.btnLoading = true
            this.$Message.loading({
                content: "正在修改社区",
            })
            try {
                this.$post("/gateway/api/sysmartscreen/pc/terminal/saveTerminalUUID", {
                    terminalUuid: this.detailMessage.terminalUuid,
                    activeTime: this.detailMessage.activeTime && this.detailMessage.activeTime != "" ? this.$core.formatDate(new Date(this.detailMessage.activeTime), "yyyy-MM-dd hh:mm:ss") : "",
                    activeStatus: this.detailMessage.activeStatus,
                    appCode: this.detailMessage.appCode,
                    version: this.detailMessage.version,
                    terminalGroupId: this.detailMessage.terminalGroupId,
                    orgCode,
                }).then(res => {
                    this.$Message.destroy()
                    this.btnLoading = false
                    if (res.code == 200) {
                        this.$Message.success({
                            background: true,
                            content: "修改成功",
                        })
                        this.changeSwitchStatus(false)
                        this.getList()
                        return
                    } else {
                        this.$Message.error({
                            content: "修改失败",
                            background: true,
                        })
                        return
                    }
                })
            } catch {
                this.$Message.destroy()
                this.btnLoading = false
                this.$Message.error({
                    content: "修改失败",
                    background: true,
                })
                return
            }
        },
        async getList() {
            this.loading = true
            await this.$get("/gateway/api/sysmartscreen/pc/terminal/terminalPage", {
                activeStatus: "", // 激活状态
                terminalGroupId: "", // 群id
                appCode: this.searchData.appCode, // 应用编码
                page: this.page,
                pageSize: this.pageSize,
                orgCode: this.searchData.orgCode,
            }).then(res => {
                this.loading = false
                this.tableData = res.dataList.map(item => {
                    return {
                        ...item.set,
                        ...item,
                    }
                })
                this.allTableData = this.tableData
                this.total = res.maxCount
            })
        },
    },
}
</script>
